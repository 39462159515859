import { useAppSelector } from 'app/hooks';
import { TitlebarState, titlebarState } from './titlebarSlice';
import sprite from "content/icons/sprite.symbol.svg";
import { Link } from 'react-router-dom';
import { StatusPill } from './Components/statusPill';
import { RootState } from 'app/store';
import { selectIsBallPark } from 'pages/dashboard/components/Grid/components/grantConstraintSlice';
import { useSelector } from 'react-redux';
import { Localizer } from 'infrastructure/localization/localizer';
import { ActionButtons } from "../../core/actionButtons/actionButtons";
import { BudgetPeriodResponseModel, BudgetType } from "../../services/api/responseModels/budgetPeriodResponseModel";
import { Action } from "../../models/enums/action";
import { EditState } from "../../models/enums/editState";
import { FinancialReportType } from "../../models/enums/financialReportType";
import config from "../../config/config";
import { useAuth } from "../../infrastructure/routing/auth";
import Icon from "../../core/icon/icon";
import { Tooltip } from "../../core/tooltip/tooltip";
import { UserRole } from "../../models/enums/userRole";

interface ITitleBarProps {
	budgetPeriod: BudgetPeriodResponseModel;
	showActionButtons: boolean;
}

export const TitleBar = (props: ITitleBarProps) => {
	const isBallPark = useSelector<RootState>(state => selectIsBallPark(state)) as boolean;
	const titleBar = useAppSelector(titlebarState) as TitlebarState;
	const auth = useAuth();
	const isTestEnvironment = config.environment !== "Produktion" || auth.user?.userRole === UserRole.Administrator;

	const renderTestInfo = () => isTestEnvironment && props.budgetPeriod && auth.user &&
		<>
			<Tooltip message={`ExternalApplicationId: ${auth.user?.externalApplicationId}, RecordId: ${auth.user?.recordId}, Action: ${auth.user?.action} (${Action[auth.user?.action]}) Budget type: ${props.budgetPeriod.type} (${BudgetType[props.budgetPeriod.type]}),
            Read/write: ${auth.user?.editState} (${EditState[auth.user.editState]}), min/max: ${props.budgetPeriod.minAmount}/${props.budgetPeriod.maxAmount}, maxCategoryDeviation: ${props.budgetPeriod.categoryMaxDeviation}%, subCategoryMaxDeviation: ${props.budgetPeriod.subCategoryMaxDeviation}%
            ${props.budgetPeriod.financialReportYearId ? "Financial report for year ID: " + props.budgetPeriod.financialReportYearId : ""}
            ${props.budgetPeriod.financialReportType === FinancialReportType.Single ? 'Financial report type is "single"' : ""}`} placement={"top"} className={"d-inline-block"}>
				<div style={{ width: 'fit-content', }}>
					<Icon icon={"toolbox"}></Icon>
				</div>
			</Tooltip>
		</>;

	const renderBreadcrumbs = () => {

		if (titleBar.breadcrumbs.some(breadcrumb => breadcrumb.text)) {
			return titleBar.breadcrumbs.map(function (breadcrumb, index) {
				if (breadcrumb.url) {
					return <div key={index}>
						<Link to={breadcrumb.url} className={`breadcrumb-title`}>
							{breadcrumb.text}
						</Link>

						{titleBar.breadcrumbs.indexOf(breadcrumb) < titleBar.breadcrumbs.length - 1 &&
							<span>
								<svg className="embla-icon breadcrumb-arrow" aria-hidden="true" version="1.1"
									xmlns="http://www.w3.org/2000/svg">
									<use href={sprite + "#arrow-right"} />
								</svg>
							</span>
						}
					</div>
				}
				else {
					return <p key={index} className={`breadcrumb-title`}>
						{breadcrumb.text}
					</p>
				}
			});
		}

		return;
	};

	return (
		<div>
			<div className="title-bar">
				<div className="breadcrumbs-container">
					{renderBreadcrumbs()}
				</div>

				<div className="title-bar-header">
					<div className="title-bar-header-text">
						<h2>{titleBar.title} {isBallPark && Localizer.pipelineTitle()}</h2>
					</div>

					{titleBar.status &&
						<div className="title-bar-status">
							<StatusPill text={titleBar.status} />
						</div>
					}
				</div>

				<div className="sub-title-bar">
					<div className="sub-title d-flex">
						{props.budgetPeriod &&
							<span>{props.budgetPeriod.comment}</span>
						}
						{renderTestInfo()}
					</div>
					<div className="sub-title-bar-actions">
						{props.showActionButtons &&
							<ActionButtons projectTitle={titleBar.title} budgetPeriod_Currency={props.budgetPeriod.currency} projectSupplementEnabled={props.budgetPeriod.projectSupplementEnabled} supplementChoiceEnabled={props.budgetPeriod.supplementChoiceEnabled} institutions={props.budgetPeriod.institutions} budgetPeriodState={props.budgetPeriod.status} />
						}
					</div>
				</div>
			</div>
		</div>
	);
}
